<template>
  <div>
    <div class="d-flex flex-column" dir="rtl" v-if="content?.form?.iframe_url">
      <div class="d-flex flex-row">
        <div class="f20-l30-b pointer" @click="$emit('close')">X</div>
        <div class="f20-l30-b flex-grow-1 text-center" v-html="content.title"></div>
      </div>
      <div class="f14-l17 flex-grow-1" v-html="content.subtitle"></div>
      <div class="f14-l17 red" id="popup-error-message"></div>
      <div class="f14-l17 red" id="popup-error-message-description"></div>
      <LoadingIndicator dir="ltr" v-if="popupLoadingIndicator" />
      <iframe :src="content?.form?.iframe_url" :class="classSelector()" :allow="this.$isGOOGLE() ? 'payment' : null"></iframe>
    </div>

    <div class="d-flex flex-column" dir="rtl" v-else-if="content?.form">
      <div class="d-flex flex-row">
        <div class="f20-l30-b pointer" @click="$emit('close')">X</div>
        <div class="f20-l30-b text-center flex-grow-1" v-html="content.title"></div>
      </div>
      <div v-if="content.description" class="f14-l17 margin-tb-20 text-center">{{content.description}}</div>
      <form class="formfield-round-border formfield-16" dir="rtl" :action="content.form?.submit?.url || content.form?.submit?.href || content.form.button.href" ref="form" :method="content.form?.submit?.method || `POST`" @submit.prevent="false" @keyup.enter="onSubmit">
        <FormFieldComponent :field="customizeField(field)" v-for="(field,idx) in content.form.fields" :key="field.data_name || 'field_' + idx" />
        <div v-if="content.form.new_address" class="red f14-l17 formfield-margin-wrapper padding-tb-10 pointer"
          @click="openpopup(content.form.new_address.popup.form)">{{content.form.new_address.popup.form.title}}</div>
        <div class="f14-l17 red text-center" v-if="errorMessage" v-html="errorMessage"></div>
        <ButtonComponent :caption="content.form?.submit?.label || content.form.button.label" @click="onSubmit" @keyup.enter="onSubmit" class="margin-top-20" />
      </form>

    </div>

    <div class="d-flex flex-column" dir="rtl" v-else-if="content?.body">
      <div class="d-flex flex-row">
        <div class="f20-l30-b pointer" @click="$emit('close')">X</div>
        <div class="f20-l30-b flex-grow-1 text-center" v-html="content.header.title_html"></div>
      </div>
      <HorizontalSplitter class="margin-tb-20" />
      <div class="f14-l17 red-link" v-html="content.body.text_html"></div>
      <HorizontalSplitter class="margin-tb-20" />
      <div class="d-flex flex-row">
        <ButtonComponent class="flex-grow-1 margin-lr-5" v-for="btn in content.buttons" :key="btn.label" :caption="btn.label" @click="btnAction(btn)" @keyup.enter="btnAction(btn)" />
        <!-- <ButtonComponent :caption="btn.label" v-if="!btn.cancel"  /> -->
      </div>
    </div>

    <div class="d-flex flex-column" dir="rtl" v-else-if="content?.dish">
      <div class="d-flex flex-row">
        <div class="f20-l30-b pointer" @click="$emit('close')">X</div>
        <div class="f20-l30-b flex-grow-1 text-center" v-html="content.title"></div>
      </div>
      <HorizontalSplitter class="margin-tb-20" />

      <div class="d-flex flex-row">
        <ImgImage :img="$responsive(content.dish.image)" />
        <div class="d-flex flex-column padding-10 w-100">
          <div class="d-flex flex-row w-100">
            <div class="f15-l18-m flex-grow-1 ">{{content.dish.title}}</div>
            <div class="f15-l18-m text-nowrap">
              <PriceComponent :value="content.dish.price" />
            </div>
          </div>
          <div class="f14-l19 margin-top-5 c58">{{content.dish.description}}</div>
          <div class="f14-l19 c58" v-for="(option,idx) in content.dish.deal_options" :key="idx">{{option.text}}</div>
          <div class="f14-l19 bold">{{content.dish.amount.text}}</div>
          <div class="f14-l19 red">{{content.dish.cook.text}}</div>
        </div>

      </div>
      <form class="formfield-round-border formfield-16" :action="content.dish.dish_comment.field.update_url" ref="form" method="POST">
        <FormFieldComponent :field="content.dish.dish_comment.field" />
      </form>
      <HorizontalSplitter class="margin-tb-20" />
      <div class="d-flex flex-row">
        <ButtonComponent class="flex-grow-1 margin-lr-5" v-for="btn in content.buttons" :key="btn.text" :caption="btn.text" @click="btnAction(btn)" @keyup.enter="btnAction(btn)" :variant="btn.variant || 'red'" />
      </div>
    </div>

    <div class="d-flex flex-column" dir="rtl" v-else-if="content?.str">
      <div class="d-flex flex-row" v-html="content.str">
      </div>
    </div>

    <div class="d-flex flex-column" dir="rtl" v-else-if="contentIsString()">
      <div class="d-flex flex-row" v-html="content">
      </div>
    </div>

    <WizardPopupComponent v-if="wizard" :wizard="wizard" @close="$emit('close')" />
  </div>
</template>
<script>
import {formSubmit, fetch2} from '@/modules/configuration.js';
import {openCart} from '@/modules/utils.js';
import ButtonComponent from "@/components/form/ButtonComponent"
import HorizontalSplitter from "@/components/generic/HorizontalSplitter.vue"
import ImgImage from "@/components/generic/ImgImage.vue"
import FormFieldComponent from "@/components/form/FormFieldComponent"
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import PriceComponent from "@/components/generic/PriceComponent.vue";
import WizardPopupComponent from "@/components/search/WizardPopupComponent";
export default {
  components: {
    ButtonComponent,
    HorizontalSplitter,
    ImgImage,
    FormFieldComponent,
    LoadingIndicator,
    PriceComponent,
    WizardPopupComponent
  },
  props: {
    content: Object,
    url: Object
  },
  data() {
    return {
      popupLoadingIndicator: false,
      loading: false,
      wizard: null,
      errorMessage: null
    }
  },
  mounted() {
    const u = this.url?.url;
    if (u && u.indexOf('wizard') >= 0) {
      this.getData(this.url.url);
    }
  },
  methods: {
    async getData(url) {
      this.loading = true;
      const data = await fetch2(this, url);
      if (Object.keys(data).length ===0) return ;

      this.wizard = data;
      this.loading = false;
    },
    async onSubmit() {
      let form = this.$refs.form;
      const response = await formSubmit(this, form, null, null);
      // eslint-disable-next-line
      if (response.hasOwnProperty("success") && response.success === false && response.error) {
        this.errorMessage = response.error;
        return;
      }
      this.$emit('close', response.success, response, this.content?.requestId);
    },
    async btnAction(btn) {
      this.$emit('close');
      let link = btn.url || btn.href;
      if (link) {
        if (link.indexOf("add_to_cart") > 0) {
          let response = await formSubmit(this, null, link);
          if (response.popup_content || response?.prompt_json?.popup_content) {
            openCart(this,response);
            // if (this.$isDesktop(true) && response?.cart?.url) {
            //   this.$store.dispatch('showPopupWithUrl', response.cart.url);
            // } else if (response.popup_content || response?.prompt_json?.popup_content) {
            //   this.$store.dispatch('showPopup', response.popup_content || response?.prompt_json?.popup_content);
            // }
          }
        } else {
          if (this.$route.fullPath == link) {
            this.$router.go(0);
          } else if (link.indexOf("/") == 0) {
            const path = link.split('?')[0];
            if (this.$route.path == path){
              window.location.href = link;
            } else {
              this.$router.push(link);
            }
          }
        }
      }
    },
    contentIsString() {
      return this?.content instanceof String;
    },
    openpopup(json) {
      this.$emit('close');
      this.$store.dispatch('showPopup', {form: json, requestId: this.content?.requestId});
    },
    customizeField(field) {
      field["desktopClass"] = "col-12";
      return field;
    },
    classSelector() {
      return this.content.form.style + '_iframe';
    }
  },
  watch: {
    async $route(to/*, from*/) {
      if (to.hash === "#loading") {
        this.popupLoadingIndicator = true;
      }
      if (to.hash === "#loaded") {
        this.popupLoadingIndicator = false;
      }
    }
  }
}
</script>
<style scoped>
.formfield-no-margin .formfield-margin-wrapper {
  margin: 0;
}

.card_iframe {
  width: 300px; 
  height: 450px; 
  margin: auto;
}
.credit_iframe {
  width: 300px; 
  height: 450px; 
  margin: auto;
}

.bit_iframe {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: fixed;
  border: 0;
  top: 0;
  right: 0;
  background-color: transparent !important;
}
.apple_iframe {
  width: 300px; 
  height: 300px; 
  margin: auto;
}

.google_iframe {
  width: 300px; 
  height: 300px; 
  margin: auto;
}

</style>