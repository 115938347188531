<template>
  <div class="form-group row">
    <label :for="name" class="col-12 col-form-label">
      <span v-if="field.required" class="required">*</span>
      {{label}}
    </label>
    <span v-if="field.description" class="col-12 df16-l18-l f14-l19-l col-form-description description" v-html="field.description" ></span>
    <div class="col-12">
      <textarea class="form-control" :id="name" :name="name" rows="3" :placeholder="placeholder" v-model="fieldValue" @input="updateCounter" :maxlength="max_length" />
      <div v-if="this.field.max_length_text" class="text-muted">{{ maxLengthText }}</div>
      <!-- <input :type="type" class="form-control-plaintext" :id="name" aria-describedby="emailHelp" :name="name"> -->
      <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
      <div class="invalid-feedback show" :id="`${name}invalid`"></div>
    </div>
  </div>
</template>
<script>
import {formSubmit} from '@/modules/configuration.js'
export default {
  props: {
    label: String,
    type: String,
    name: String,
    placeholder: String,
    updateUrl: String,
    field: Object,
  },
  data() {
    return {
      fieldValue: "",
      ignoreChange: false,
    }
  },
  mounted() {
    if (this.field?.text) {
      this.ignoreChange = true;
      this.fieldValue = this.field.text || "";
      this.ignoreChange = false;
    }
  },
  computed: {
    remainingChars() {
      // Calculate the number of remaining characters
      return this.max_length - this.fieldValue.length;
    },
    max_length() {
      // Prioritize the max_length from field property, default to 500
      return this.field.max_length || 500;
    },
    maxLengthText() {
      // Concatenate max_length_text with remaining characters
      return `${this.field.max_length_text || ''} ${this.remainingChars}`;
    }
  },
  methods: {
    updateCounter() {
      // Ensure the input text does not exceed the maximum length
      if (this.fieldValue.length > this.max_length) {
        this.fieldValue = this.fieldValue.slice(0, this.max_length);
      }
    }
  },
  watch: {
    fieldValue(value) {
      if (this.ignoreChange) return;
      if (this.updateUrl) {
        formSubmit(this, null, this.updateUrl, {[this.name]: value}).then();
      }
    },
    field(value) {
      if (value.text) {
        this.ignoreChange = true;
        this.fieldValue = value.text || "";
        this.ignoreChange = false;
      }
    }
  }
}
</script>
<style scoped>
.required {
  color: red;
  margin-left: 5px;
}
@media(min-width: 768px){
  .description {
    font-size:16px !important;
  }
}
@media(max-width: 768px) {
  .description {
    font-size:14px !important;
  }
}
</style>