<template>
  <img :src="fixurl(src)" :class="clazz">
</template>
<script>
export default {
  props: {
    src: String,
    clazz: String
  },
  methods: {
    fixurl(src) {
      //if (src)
        //return src.replace("http://", "https://");
      return src;
    }
  }
}
</script>
