<template>
  <div dir="rtl">
    <div class="d-flex flex-column" dir="rtl" v-if="!loading">
      <div class="d-flex flex-row">
        <div class="f20-l20 pointer" @click="$emit('close')">X</div>
        <div class="f20-l20-b bold flex-grow-1 text-center" v-html="data.title"></div>
      </div>
      <form class="formfield-round-border nominusmargin" enctype="application/x-www-form-urlencoded" dir="rtl" :action="data.form?.submit?.url || data.form?.submit?.href || data.form.button.href" ref="formSearchPopup" :method="data.form?.submit?.method || `POST`" @submit.prevent="false" @keyup.enter="onSubmit">
        <div class="margin-top-10"></div>
        <FormFieldComponent v-for="(field,idx) in data.form.fields" :key="idx" :field="customizeField(field)" />
        <div class="invalid-feedback">{{errorMessage}}</div>
        <ButtonComponent :caption="data.form?.submit?.label || data.form.button.label" @click="onSubmit" @keyup.enter="onSubmit" class="margin-top-20" />
      </form>
    </div>
  </div>
</template>
<script>
/* global $ */
import {formSubmit, fetch3} from '@/modules/configuration.js'
import FormFieldComponent from "@/components/form/FormFieldComponent";
import ButtonComponent from "@/components/form/ButtonComponent"
export default {
  components: {
    FormFieldComponent,
    ButtonComponent
  },
  props: {
    forceSearch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      errorMessage: "",
      loading: true,
      data: {}
    }
  },
  mounted() {
    this.getData("/search_popup");
  },
  methods: {
    async getData(url) {
      this.loading = true;
      const fromPage = this.$route.fullPath;
      const searchPopupLink = $("#searchPopupLink").attr("url");
      const data = await fetch3(this, {
        url: (searchPopupLink ? searchPopupLink : url),
        addQueryString: !searchPopupLink,
        queryString: "from_page=" + encodeURIComponent(fromPage)
      });
      if (Object.keys(data).length ===0) return ;

      this.data = data.popup.popup_content;
      this.loading = false;
    },
    async onSubmit() {
      this.errorMessage = "";
      const form = this.$refs.formSearchPopup;
      let gotoSearch = form.query?.value;
      if (this.forceSearch || gotoSearch) {
        this.$emit('close');
        form.method = 'GET';
        formSubmit(this, form, '/search', null);
        return;
      }
      const data = await formSubmit(this, form, null, null);
      if (data?.success) {
        this.$emit('close', true, data?.reload_path);
        return;
      }
      this.errorMessage = data.error;
    },
    customizeField(field) {
      field["desktopClass"] = "col-12";
      return field;
    }
  }
}
</script>
<style scoped>
</style>