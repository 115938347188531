<template>
  <div v-if="!loading" dir="rtl">

    <teleport to="#modalHeader">
      <div class="d-flex flex-column margin-20">
        <div class="d-flex flex-row">
          <div class="f26-l30-m flex-grow-1" v-html="data.title"></div>
          <div class="f26-l30 bold c38  pointer" @click="$emit('close')">X</div>
        </div>
        <div class="f14-l17">{{data.requested_delivery_date}}</div>
        <a v-if="data.cook" :href="data.cook.href" class="red-link f14-l17">{{data.cook.text}}</a>
      </div>
      <HorizontalSplitter />
    </teleport>

    <div v-if="data.message" class="text-center f16-l21" v-html="data.message"></div>

    <div class="row mw740">
      <div class="col-12 col-xl-7 d-vertical-split" >

        <div class="d-flex flex-column" dir="rtl" v-for="(dish,idx) in data.order_dishes" :key="idx">
          <div class="d-flex flex-row margin-top-20">
            <div class="pointer margin-l-10" style="width:13px;" @click="removeDish(dish)">X</div>
            <ImgImage :img="$responsive(dish.image)" class="dektop-only margin-l-10" />
            <div class="flex-grow-1 d-flex flex-column margin-l-10">
              <div class="f15-l18-m pointer" @click="showDish(dish)">{{ dish.name.title }}</div>
              <div class="f14-l18-l c58 flex-grow-1">{{dish.description}}</div>
              <ul class="f14-l18 options-list">
                <li class="f14-l18"  v-for="(option,idx) in dish.deal_options" :key="idx">
                  {{option.text}}
                </li>
              </ul>
              <div class="f15-l18-m">{{dish.amount.title}}: {{dish.amount.amount}}</div>
            </div>
            <div class="f15-l18-m text-nowrap">
              <PriceComponent :value="dish.price" />
            </div>
          </div>
          <FormFieldComponent class="formfield-14" :field="dish.dish_comment.field" />
        </div>
        <HorizontalSplitter v-if="haveItems" class="margin-tb-20" />
        <div v-if="data.user_comment">
          <FormFieldComponent class="formfield-14" :field="data.user_comment.field" />
        </div>
        <HorizontalSplitter class="margin-tb-20" v-if="haveItems" />
      </div>
      <div class="col-12 col-xl-5">
        <div class="d-flex flex-column " v-for="(row,idx) in this.orderInfo.json_vue" :key="idx">
          <div v-if="!'total_price|minimum_order_price_alert|'.includes(idx)" class="d-flex flex-row margin-10">
            <div class="f15-l18-l flex-grow-1">{{row.text}}</div>
            <div class="f15-l18-m">
              <PriceComponent :value="(idx in orderInfoValues) ? orderInfoValues[idx] : row.val" />
            </div>
          </div>
        </div>
        <div class="d-flex flex-column margin-10">
          <div class="d-flex flex-row">
            <div class="f15-l18-m flex-grow-1">{{this.orderInfo.json_vue.total_price.text}}</div>
            <div class="f15-l18-m">
              <PriceComponent :value="orderInfoValues.total_price" />
            </div>
          </div>
        </div>
        <div class="d-flex flex-column margin-10" v-if="data.order_summary.minimum_order_price">
          <MinOrderPriceAlertComponentVue :title="data.order_summary.minimum_order_price.title"
            :mainText="getMainText(data.order_summary.minimum_order_price)"
            :addText="this.orderInfo.json_vue.minimum_order_price_alert.addition_text" 
            :urlLabel="data.order_summary.minimum_order_price.link.text"
            :url="data.order_summary.minimum_order_price.link.href" />
          <!-- <div class="grey-bg margin-top-10 padding-tb-10">
            <div class="d-flex flex-column text-center">
              <div class="f18-l20-b">{{data.order_summary.minimum_order_price.title}}</div>
              <div class="f14-l18">
                <div v-if="data.order_summary.minimum_order_price.carrier_delivery">
                  {{data.order_summary.minimum_order_price.carrier_delivery.label}} {{data.order_summary.minimum_order_price.carrier_delivery.value}}
                </div>
                <div v-if="data.order_summary.minimum_order_price.self_pickup">
                  {{data.order_summary.minimum_order_price.self_pickup.label}} {{data.order_summary.minimum_order_price.self_pickup.value}} 
                </div>
              </div>
              <div class="f18-l20-b margin-top-10" >{{ this.orderInfo.json_vue.minimum_order_price_alert.addition_text }}</div>
              <a class="f14-l18 red-link margin-top-10" :href="data.order_summary.minimum_order_price.link.href">{{data.order_summary.minimum_order_price.link.text}}</a>
            </div>

          </div> -->
        </div>
      </div>

    </div>
    <teleport to="#modalFooter">
      <HorizontalSplitter />
      <div class="d-flex flex-row  margin-20" dir="rtl">
        <ButtonComponent class="flex-grow-1 margin-lr-5" v-for="btn in data.buttons" :key="btn.text" :caption="btn.text" @click="btnAction(btn)" @keyup.enter="btnAction(btn)" :variant="btn.variant || 'red'" />
      </div>
    </teleport>
  </div>
</template>
<script>
import {fetch2, formSubmit} from '@/modules/configuration.js'
import ButtonComponent from "@/components/form/ButtonComponent"
import HorizontalSplitter from "@/components/generic/HorizontalSplitter.vue"
import ImgImage from "@/components/generic/ImgImage.vue"
import FormFieldComponent from "@/components/form/FormFieldComponent"
import PriceComponent from "@/components/generic/PriceComponent.vue"
import MinOrderPriceAlertComponentVue from '../MinOrderPriceAlertComponent.vue'
export default {
  components: {
    ButtonComponent,
    HorizontalSplitter,
    ImgImage,
    FormFieldComponent,
    PriceComponent,
    MinOrderPriceAlertComponentVue
  },
  data() {
    return {
      loading: true,
      data: {},
      orderInfo: {},
    }
  },
  props: {
    url: Object
  },
  mounted() {
    const u = this.url?.url;
    if (u && u.indexOf('cart_panel') >= 0) {
      this.getData(this.url.url);
    }
    this.$store.dispatch("fetchCartQuantityData");
  },
  computed: {
    haveItems() {
      return (this.data?.order_dishes?.length || 0) > 0;
    },
    orderInfoValues() {
      return this.orderInfo.json_vue.total_price.totals_for_delivery_method[this.orderInfo.delivery_method];
    }
  },
  methods: {
    async removeDish(dish) {
      await formSubmit(this, null, dish.remove_dish.href);
      if (this.url?.url) {
        this.getData(this.url.url);
      }
    },
    async getData(url) {
      const data = await fetch2(this, url);
      if (Object.keys(data).length ===0) return ;

      this.data = data;
      this.orderInfo = data.order_summary.order_info;
      this.$store.dispatch("fetchCartQuantityData");
      this.loading = false;
    },
    onSubmit() {
      let form = this.$refs.form;
      formSubmit(this, form);
    },
    showDish(dish) {
      this.$router.push(dish.name.href);
      this.$emit('close');
    },
    async btnAction(btn) {
      this.$emit('close');
      let link = btn.url || btn.href;
      if (this.haveItems && link) {
        if (link.indexOf("add_to_cart") > 0) {
          let response = await formSubmit(this, null, link);
          if (response.popup_content || response?.prompt_json?.popup_content) {
            this.$store.dispatch('showPopup', response.popup_content || response?.prompt_json?.popup_content);
          }
        } else {
          if (link.indexOf("/") == 0) {
            this.$router.push(link);
          }
        }
      }
    },
    getMainText(minOrderPrice) {
      let mainText = '';

      if (minOrderPrice.carrier_delivery) {
        mainText += `<div>${minOrderPrice.carrier_delivery.label} ${minOrderPrice.carrier_delivery.value}</div>`;
      }

      if (minOrderPrice.self_pickup) {
        mainText += `<div>${minOrderPrice.self_pickup.label} ${minOrderPrice.self_pickup.value}</div>`;
      }

      return mainText.trim();
    }
  }
}
</script>
<style scoped>
.options-list {
  padding-inline-start: 15px;
  padding-left: 0px;
}
@media (min-width: 1200px) {
    .d-vertical-split {
      border-left: 1px solid #0000001E;
    }
  }
</style>